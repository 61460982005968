.offers {
    width: 65%;
    height: 60vh;
    display: flex;
    margin: auto;
    padding: 0px 140px;
    margin-top: 50px;
    margin-bottom: 50px;
    background: linear-gradient(180deg, #000000 0%, #727272 60%);
    animation: floating 3s ease-in-out infinite;
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.offers-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.offers-left .h1_dark {
    color: #171717;
    font-size: 80px;
    font-weight: 600;
}

.h1_light {
    color: white;
    font-size: 80px;
    font-weight: 600;
}

.offers-left .p_dark {
    color: #171717;
    font-size: 22px;
    font-weight: 600;
}

.p_light {
    color: white;
    font-size: 22px;
    font-weight: 600;
}

.offers-left button {
    width: 282px;
    height: 70px;
    border-radius: 35px;
    background: #ff4141;
    border: none;
    color: white;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
}

.offers-right {
    /* flex: 1; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 50px;
}