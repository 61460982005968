.swiper-button-prev,
.swiper-button-next{
  font-size: x-small;
  z-index: 10;
  color: white;
  background-color: #4a4e69;
  border-radius: 50%;
  padding: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  transition: all 0.3s ease-in-out;
}

.swiper-button-prev:after,
.swiper-button-next:after{
  font-size: small;
}

.swiper-button-prev {
  left: 5px;
}

.swiper-button-next {
  right: 5px;
}

/* Hover effect for buttons */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #9a8c98;
  /* Lighten on hover */
  transform: scale(1.1);
  /* Enlarge on hover */
}

/* Styling for pagination bullets */
.swiper-pagination-bullet {
  background-color: gray;
}

.swiper-pagination-bullet-active {
  background-color: black;
}

/* Adjustments for mobile screens */
@media (max-width: 767px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}