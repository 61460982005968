.carousel-container {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  min-height: 400px;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;
  height: 500px;
  min-height: 400px;
}

.carousel-item {
  position: absolute;
  width: 65%;
  height: 85%;
  transition: transform 0.5s ease, z-index 0.5s, opacity 0.5s;
  transform-origin: center;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease;
}

.carousel-item.active img:hover {
  transform: scale(1.15);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.carousel-item.active img {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

.carousel-item.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.carousel-item.left img,
.carousel-item.right img {
  transform: scale(0.85);
  opacity: 0.7;

}

.carousel-item.inactive img {
  transform: scale(0.6);
  opacity: 0.5;
}

/* Button styles */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 4rem;
  background-color: rgba(255, 254, 254, 0.195);
  color: rgb(90, 88, 88);
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 10;
}

.carousel-button.left {
  left: 10px;
}

.carousel-button.right {
  right: 10px;
}

/* Anchor styling */
.carousel-item a {
  display: block;
  height: 100%;
}

@media (max-width: 500px) {
  .carousel-container {
    height: 300px;
    /* Decreased height */
  }

  .carousel {
    height: 300px;
    /* Decreased height */
  }
}