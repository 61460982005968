.productCard {
  width: 15rem;
  margin: 0.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Combined transition */
}

.productCard .textPart {
  transition: transform 0.3s ease-out;
}

.productCard:hover .textPart {
  transform: translateY(-1rem);
}

.productCard:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Media Query for Mobile Devices */
@media (max-width: 640px) {
  .productCard {
    width: calc(50% - 1rem);
    margin: 0.5rem;
    /* Space between cards */
  }

    .productCard .textPart p {
      font-size: 0.75rem;
      /* Decrease title font size */
    }
  
    .productCard .textPart .font-bold {
      font-size: 0.7rem;
      /* Smaller size for brand on mobile */
    }
  
    .productCard .textPart .font-semibold {
      font-size: 0.75rem;
      /* Adjust if needed */
    }

}